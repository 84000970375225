import React from "react";
import classNames from "classnames";

import { getComplianceLevel, WCAGLevel } from "@frontend/utils/accessibility.utils";
import { getWcagContrastRatio } from "@frontend/utils/color.utils";

import { Toggle } from "@components/form-controls";
import { AlertFillIcon, CheckboxCircleFillIcon, ErrorWarningFillIcon } from "@components/icons/remix-icons";
import { NewBadge } from "@components/new-badge/new-badge";

type ColorContrastCheckerProps = {
  textColor: string;
  backgroundColor: string;
  defaultAAA?: boolean;
};

export const ColorContrastChecker: React.FC<ColorContrastCheckerProps> = (props) => {
  const [isAAA, setIsAAA] = React.useState(props.defaultAAA ?? false);

  const ratio = React.useMemo(
    () => getWcagContrastRatio(props.textColor, props.backgroundColor),
    [props.textColor, props.backgroundColor]
  );

  const complianceConfig = {
    AA: {
      optimal: {
        Icon: CheckboxCircleFillIcon,
        color: "tw-text-success-900",
        bgColor: "tw-bg-success-100",
        title: "Meets AA Standards",
        body: "Your contrast levels meet AA accessibility standards, ensuring readability across a broad audience."
      },
      acceptable: {
        Icon: AlertFillIcon,
        color: "tw-text-warning-900",
        bgColor: "tw-bg-warning-100",
        title: "Near AA Compliance",
        body: "Almost compliant with AA standards. A slight adjustment in contrast will ensure accessibility compliance."
      },
      insufficient: {
        Icon: ErrorWarningFillIcon,
        color: "tw-text-destructive-900",
        bgColor: "tw-bg-destructive-100",
        title: "Below AA Compliance",
        body: "Current contrast does not meet AA standards. Adjustments needed to meet accessibility requirements."
      }
    },
    AAA: {
      optimal: {
        Icon: CheckboxCircleFillIcon,
        color: "tw-text-success-900",
        bgColor: "tw-bg-success-100",
        title: "Meets AAA Standards",
        body: "Your contrast levels meet AAA accessibility standards, providing the highest level of readability."
      },
      acceptable: {
        Icon: AlertFillIcon,
        color: "tw-text-warning-900",
        bgColor: "tw-bg-warning-100",
        title: "Near AAA Compliance",
        body: "Close to AAA standards. A minor increase in contrast will meet the highest accessibility level."
      },
      insufficient: {
        Icon: ErrorWarningFillIcon,
        color: "tw-text-destructive-900",
        bgColor: "tw-bg-destructive-100",
        title: "Below AAA Compliance",
        body: "Current contrast does not meet AAA standards. Consider significant adjustments for maximum readability."
      }
    }
  };

  const level = ratio !== null ? getComplianceLevel(ratio, isAAA ? WCAGLevel.AAA : WCAGLevel.AA) : null;
  const config = level !== null ? complianceConfig[isAAA ? "AAA" : "AA"][level] : null;

  if (config === null || ratio === null) {
    return (
      <div
        className={classNames(
          `tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded-lg tw-border tw-p-2 tw-text-destructive-600`
        )}
      >
        <header className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-space-x-2">
            <ErrorWarningFillIcon className={`tw-h-5 tw-w-5`} />
            <h2 className="tw-text-lg tw-font-semibold">Invalid Color Values</h2>
          </div>
        </header>
        <p className="tw-text-sm tw-font-medium">Please provide valid hex color codes (e.g., #FFFFFF).</p>
      </div>
    );
  }

  const { Icon } = config;

  return (
    <div
      className={classNames(
        `tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded-lg tw-p-2 ${config.color}`,
        config.bgColor
      )}
    >
      <header className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Icon className={`tw-h-5 tw-w-5`} />
          <h2 className="tw-text-lg tw-font-semibold">{config.title}</h2>
        </div>
        <div className="tw-flex tw-items-center tw-space-x-2 tw-font-medium">
          <span className={classNames("tw-text-sm tw-text-neutral-900", { "!tw-text-neutral-500": !isAAA })}>AA</span>
          <Toggle checked={isAAA} onChange={() => setIsAAA(!isAAA)} />
          <span className={classNames("tw-text-sm tw-text-neutral-900", { "!tw-text-neutral-500": isAAA })}>AAA</span>
        </div>
      </header>
      <p className="tw-text-sm tw-font-medium">
        <NewBadge
          variant="secondary-soft"
          label={Number(ratio.toFixed(1)) + ":1"}
          className="tw-float-right tw-ml-2 tw-bg-white"
        />
        {config.body}
      </p>
    </div>
  );
};

export default ColorContrastChecker;
