export enum WCAGLevel {
  AA = "AA",
  AAA = "AAA"
}

export enum ComplianceLevel {
  OPTIMAL = "optimal",
  ACCEPTABLE = "acceptable",
  INSUFFICIENT = "insufficient"
}

const CONTRAST_THRESHOLDS = {
  [WCAGLevel.AA]: {
    optimal: 4.5,
    acceptable: 3.0
  },
  [WCAGLevel.AAA]: {
    optimal: 7.0,
    acceptable: 4.5
  }
} as const;

export const getComplianceLevel = (contrastRatio: number, wcagLevel: WCAGLevel = WCAGLevel.AA): ComplianceLevel => {
  const thresholds = CONTRAST_THRESHOLDS[wcagLevel];

  if (contrastRatio >= thresholds.optimal) return ComplianceLevel.OPTIMAL;
  if (contrastRatio >= thresholds.acceptable) return ComplianceLevel.ACCEPTABLE;
  return ComplianceLevel.INSUFFICIENT;
};
